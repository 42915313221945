import { Spinner } from "@dzambalaorg/mui-page-layout";

import { StyledCurrentRoot, StyledSpinnerBox } from "../../../../../shared";
import { useFetchBox } from "./useFetchBox";
import { CurrentBox } from "./box";

export const CurrentVestingBox = () => {
  const { selected, isLoading } = useFetchBox();

  if (isLoading) {
    return (
      <StyledCurrentRoot isLoading={isLoading}>
        <StyledSpinnerBox>
          <Spinner />
        </StyledSpinnerBox>
      </StyledCurrentRoot>
    );
  }

  if (!selected) {
    // TODO - add error component
    return <div></div>;
  }

  return <CurrentBox selected={selected} />;
};
