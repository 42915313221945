import type { ContractEventSignature } from "@framework/types";

export const EventRouteMatch: Partial<Record<keyof typeof ContractEventSignature, string>> = {
  Released: "/asset",
  PurchaseVesting: "/vesting/boxes",
  Purchase: "/vesting/offers",
};

export const EventRouteMatchForNavigate: Partial<Record<keyof typeof ContractEventSignature, string>> = {
  Split: "/asset",
};
