import { formatDistanceToNowStrict, addSeconds, format, fromUnixTime } from "date-fns";

import { months } from "../constants";

export const formatXAxis = (date: string | null): string => {
  if (!date) {
    return "";
  }
  const parseDate = new Date(date);

  return format(parseDate, "MM/yy");
};

export const secondFromUnixConverter = (date: Date) => {
  const utcDate = new Date(date);
  return utcDate.getTime() / 1000;
};

export const getPeriodFromSecondsInDays = (seconds: number): string => {
  const now = new Date();
  const targetDate = addSeconds(now, seconds);

  return formatDistanceToNowStrict(targetDate);
};

function getGMTOffset(date: Date): string {
  const offset: number = date.getTimezoneOffset();
  const hours: number = Math.abs(Math.floor(offset / 60));
  const sign: string = offset > 0 ? "-" : "+";
  return `GMT${sign}${hours}`;
}

/** value could be as number (seconds from unix) or string (date in UTC)**/
export const formatDateFromSpecialValue = (value: number | string, withTime?: boolean): string => {
  const date = typeof value === "number" ? fromUnixTime(value) : new Date(value);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);
  const time = withTime
    ? ` at ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")} ${getGMTOffset(date)}`
    : "";

  return `${month} ${String(day).padStart(2, "0")}'${year}${time}`;
};

export const formatDateForVestingPlot = (
  prevDate: string,
  coefficient: number,
  isMonthly = false,
  hasTimeDefault = false,
): string => {
  const parseDate = new Date(prevDate);
  const hasTime = hasTimeDefault || !Number.isInteger(coefficient) || coefficient === 0;

  if (!isMonthly) {
    const coefficientInMilliseconds = coefficient * 24 * 60 * 60 * 1000;
    parseDate.setTime(parseDate.getTime() + coefficientInMilliseconds);
  } else {
    parseDate.setMonth(parseDate.getMonth() + coefficient);
  }

  const day = parseDate.getDate();
  const month = months[parseDate.getMonth()];
  const year = parseDate.getFullYear();
  const time = hasTime
    ? ` ${String(parseDate.getHours()).padStart(2, "0")}:${String(parseDate.getMinutes()).padStart(2, "0")}`
    : "";

  return `${String(day).padStart(2, "0")} ${month}, ${year} ${time}`;
};
