import { FC } from "react";
import { SvgIconProps } from "@mui/material";

import {
  Arbitrum,
  Besu,
  Binance,
  Ethereum,
  ImmutableZkEVM,
  Optimism,
  Polygon,
  Telos,
  Waterfall,
} from "@dzambalaorg/mui-icons";

import { formatDateForVestingPlot } from "./date";

export const getIconByChainId = (chainId: number): FC<SvgIconProps> | null => {
  switch (chainId) {
    case 1:
    case 11155111:
      return Ethereum;
    case 10:
      return Optimism;
    case 56:
    case 97:
      return Binance;
    case 137:
    case 80002:
      return Polygon;
    case 42161:
      return Arbitrum;
    case 10000:
    case 10001:
      return Besu;
    case 13371:
    case 13473:
      return ImmutableZkEVM;
    case 40:
    case 41:
      return Telos;
    case 1501869:
      return Waterfall;
    default:
      return null;
  }
};

const MONTH = 30;

type BasicStepData = {
  duration: number; // input duration in days
  period: number; // input period in days
  startTimestamp: string;
};

type ExponentialStepData = BasicStepData & { growthRate: number };

export const generateLinearSteppedData = (config: BasicStepData) => {
  const durationMonths = config.duration / MONTH;
  const periodMonths = config.period / MONTH;
  let prevDate = config.startTimestamp;
  const data = [];

  for (let i = 0; i < durationMonths; i++) {
    const rangeStart = i * periodMonths;

    const currentValue = (i * 100) / durationMonths;
    const formattedDate = formatDateForVestingPlot(prevDate, rangeStart > 0 ? 1 : rangeStart, true);
    prevDate = formattedDate;

    data.push({
      x: formattedDate,
      y: currentValue,
    });
  }

  data.push({
    x: formatDateForVestingPlot(prevDate, 1, true),
    y: 100,
  });

  return data;
};

export const generateExponentialSteppedData = (config: ExponentialStepData) => {
  const durationMonths = config.duration / MONTH;
  const periodMonths = config.period / MONTH;
  let prevDate = config.startTimestamp;
  const data = [];

  for (let i = 0; i <= durationMonths; i++) {
    const yValue = Math.pow(i / durationMonths, config.growthRate) * 100;
    const rangeStart = i * periodMonths;

    const formattedDate = formatDateForVestingPlot(prevDate, rangeStart > 0 ? 1 : rangeStart, true);
    prevDate = formattedDate;

    data.push({
      x: formattedDate,
      y: yValue,
    });
  }

  return data;
};

export const generateHyperbolicSteppedData = (config: BasicStepData) => {
  const durationMonths = config.duration / MONTH;
  const periodMonths = config.period / MONTH;
  let prevDate = config.startTimestamp;
  const data = [];

  for (let i = 0; i <= durationMonths; i++) {
    const yValue = (i / durationMonths / ((i + 1) / durationMonths + 1)) * 100 * 2;
    const rangeStart = i * periodMonths;

    const formattedDate = formatDateForVestingPlot(prevDate, rangeStart > 0 ? 1 : rangeStart, true);
    prevDate = formattedDate;

    data.push({
      x: formattedDate,
      y: yValue,
    });
  }

  return data;
};
