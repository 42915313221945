import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Grid2,
  Link,
  SvgIcon,
  SxProps,
  styled,
} from "@mui/material";

export const Root = styled("footer")(({ theme }) => ({
  width: "100%",
  background: "#0B1723",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {},
}));

export const StyledWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(3.2, 5, 3.2, 5),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3.2, 7, 6, 7),
  },
}));

const itemLogoStyles: SxProps = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const StyledContent = styled(Grid2)(({ theme }) => ({
  width: "100%",
  // maxWidth: "748px",
  height: "100%",

  "& .MuiGrid-container": {
    margin: theme.spacing(0),
  },

  "& #footer_grid_item_logo": {
    ...itemLogoStyles,
    padding: 0,
  },

  "& #footer_grid_item_logo_gemunion": {
    ...itemLogoStyles,
    paddingTop: 5,
  },
}));

export const StyledCopyrightWrapper = styled(Grid)(({ theme }) => ({
  ...theme.typography.caption,
  width: "100%",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.8,
  marginTop: theme.spacing(1),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    textAlign: "center",
  },
}));

export const StyledLogo = styled(SvgIcon)(() => ({
  display: "flex",
  alignItems: "center",
  width: 141,
  height: 30,
})) as any;

export const StyledLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 40,
  marginRight: theme.spacing(1),
  color: theme.palette.text.primary,
  target: "_blank",
  rel: "noopener noreferrer",
})) as typeof Link;

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  transition: "all 0.3s linear",
  background: "transparent",

  "&.Mui-expanded": {
    marginBottom: theme.spacing(4),
  },
  "&::before": {
    display: "none",
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: theme.spacing(1.5),
  color: "#FFFFFF",
  opacity: 0.8,
  fontSize: theme.spacing(2),
  fontWeight: 400,
  lineHeight: "160%",
  padding: 0,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },

  "& p": {
    margin: 0,
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: "#FFFFFF",
  opacity: 0.8,
  fontSize: theme.spacing(2.5),
  fontWeight: 700,
  lineHeight: "140%",
  letterSpacing: "-0.075px",
  padding: 0,
  background: "none",

  "&:hover": {
    textDecoration: "underline",
  },

  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: 0,
    [theme.breakpoints.down("sm")]: {},
  },
}));
