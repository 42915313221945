import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";
import { useMediaQuery, useTheme } from "@mui/material";

import { FirebaseLogin } from "@dzambalaorg/firebase-login";
import { MetamaskLoginButton } from "@dzambalaorg/login-button-metamask";
import { ParticleLoginButton } from "@dzambalaorg/login-button-particle";
import { WalletConnectLoginButton } from "@dzambalaorg/login-button-wallet-connect";

import { firebaseLoginContainerStyles, MenuVariant, PATHS } from "../../../shared";
import { WalletButton } from "../wallet";
import { NetworkButton } from "../network";
import { Root } from "./styled";
import { useMenuItems } from "./use-menu-items";
import { MenuItem } from "./menu-item";
import { IMenuVariant } from "./types";

export { IMenuVariant };

interface IMenu extends IMenuVariant {
  onCloseMenu?: () => void;
}

export const Menu: FC<IMenu> = props => {
  const { variant } = props;
  const menuLinks = useMenuItems(variant);

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const elementTop = element.getBoundingClientRect().top;
        const offsetPosition = elementTop + window.pageYOffset - 50;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <Root menuVariant={variant}>
      {isSm && <NetworkButton />}
      {menuLinks.map(item => {
        return <MenuItem key={item.title} variant={variant} menuItem={item} onCloseMenu={props.onCloseMenu} />;
      })}
      {variant === MenuVariant.INNER && (
        <React.Fragment>
          <WalletButton>
            <FirebaseLogin
              redirectUrl={`${PATHS.VestingBoxesBase}/boxes`}
              sx={firebaseLoginContainerStyles}
              withEmail={false}
              buttons={[MetamaskLoginButton, WalletConnectLoginButton, ParticleLoginButton]}
            />
          </WalletButton>
          {!isSm && <NetworkButton />}
        </React.Fragment>
      )}
    </Root>
  );
};
