import React, { PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import {
  BoxContentLeftSide,
  BoxWithTitle,
  ContentMode,
  CustomTooltip,
  InfoSvg,
  progressBarColors,
  StyledContentRightSide,
  StyledLinearProgress,
  StyledProgressBarBox,
  Vesting,
} from "../../../../shared";
import { StyledContent } from "../styled";

interface IStartingBoxContent {
  imageUrl: string;
  vesting: Vesting;
}

export const BoxContent = React.memo((props: PropsWithChildren<IStartingBoxContent>) => {
  const { imageUrl, vesting, children } = props;
  const { progressOrange, progressBlue, progressGreen } = progressBarColors;

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const withdrawnAmount = vesting.getWithdrawnDisplaying();
  const tokensCount = vesting.getContentDisplaying();
  const contentTokenTicker = vesting.getContentTicker();
  const contentDisplaying = vesting.getContentDisplaying();

  const tokensProgressId =
    vesting.getRemainingTokensAmount() < vesting.getContentAmount()
      ? "pages.tokens.current.tokensInBoxWithInfo"
      : "pages.tokens.current.tokensInBox";

  return (
    <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.contentTitle" })}>
      <StyledContent container spacing={2} direction={!isMd ? "row" : "column"} alignItems={"center"}>
        <BoxContentLeftSide
          tokenImageUrl={imageUrl}
          contentTokenTicker={contentTokenTicker}
          tokensCount={tokensCount}
          withdrawnTokensCount={withdrawnAmount}
          mode={ContentMode.ASSET}
        />

        <StyledContentRightSide size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: tokensProgressId },
                {
                  token: contentTokenTicker,
                  value: vesting.getRemainingTokensDisplaying(),
                  initialValue: contentDisplaying,
                },
              )}`}
            </Typography>
            <StyledLinearProgress
              {...progressOrange}
              content={`${vesting.getRemainingTokensPercentageDisplaying()}%` as never}
              variant="determinate"
              value={vesting.getRemainingTokensPercentageAmount()}
            />
          </StyledProgressBarBox>

          <StyledProgressBarBox>
            <Typography variant="body1" component="p">
              {`${formatMessage(
                { id: "pages.tokens.current.withdrawn" },
                {
                  token: contentTokenTicker,
                  value: withdrawnAmount,
                },
              )}`}
            </Typography>

            <StyledLinearProgress
              {...progressBlue}
              content={`${vesting.getWithdrawnPercentageDisplaying()}%` as never}
              variant="determinate"
              value={vesting.getWithdrawnPercentageAmount()}
            />
          </StyledProgressBarBox>

          <StyledProgressBarBox>
            <Box className="ProgressBar-tooltip_box">
              <Typography variant="body1" component="p">
                {`${formatMessage(
                  { id: "pages.tokens.current.releasable" },
                  {
                    token: contentTokenTicker,
                    value: vesting.getReleasableDisplaying(),
                  },
                )}`}
              </Typography>
              <CustomTooltip title={formatMessage({ id: "pages.tokens.current.releasableProgressTooltip" })}>
                <InfoSvg />
              </CustomTooltip>
            </Box>
            <StyledLinearProgress
              {...progressGreen}
              content={`${vesting.getReleasablePercentageDisplaying()}%` as never}
              variant="determinate"
              value={vesting.getReleasablePercentageAmount()}
            />
          </StyledProgressBarBox>

          {children}
        </StyledContentRightSide>
      </StyledContent>
    </BoxWithTitle>
  );
});
