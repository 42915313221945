import { FC, MouseEvent, useState } from "react";
import { useIntl } from "react-intl";
import { IconButton, SvgIcon, Typography } from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

import { ShapeType } from "@framework/types";

import { linearShapes } from "../../constants";
import { StyledReleaseBox, StyledReleaseBoxSwitcher } from "./styled";
import { calculateTokenDistribution, gweiMultiplier, setDistributions } from "./utils";

interface IReleaseBoxProps {
  tokensCount: number;
  duration: number;
  shape: ShapeType;
}

export const ReleaseBox: FC<IReleaseBoxProps> = props => {
  const { tokensCount, duration, shape } = props;

  const [index, setIndex] = useState(0);
  const { formatMessage } = useIntl();

  const switchIndex = (event: MouseEvent<HTMLButtonElement>) => {
    const buttonAttributes = event.currentTarget.attributes as unknown as Record<string, any>;
    const side: "left" | "right" = buttonAttributes["data-side"].value;

    if (side === "left") {
      if (index === 0) {
        setIndex(distributions.length - 1);
      } else {
        setIndex(prev => prev - 1);
      }
    } else {
      if (index === distributions.length - 1) {
        setIndex(0);
      } else {
        setIndex(prev => prev + 1);
      }
    }
  };

  const distributions = setDistributions(duration);

  const tokensValue = calculateTokenDistribution[distributions[index]](tokensCount, duration);

  if (!linearShapes.includes(shape)) {
    return null;
  }

  return (
    <StyledReleaseBox>
      <Typography variant="body1" component="span">
        {`${formatMessage(
          { id: "pages.vesting.currentBox.release" },
          {
            value: tokensValue * gweiMultiplier[distributions[index]].value,
            wei: gweiMultiplier[distributions[index]].text,
          },
        )}`}
      </Typography>
      <StyledReleaseBoxSwitcher>
        <IconButton data-side="left" onClick={switchIndex}>
          <SvgIcon component={ArrowCircleLeftOutlinedIcon} width={12} height={12} />
        </IconButton>
        <Typography variant="body1" component="span">
          {`${formatMessage({ id: `pages.vesting.currentBox.${distributions[index]}` })}`}
        </Typography>
        <IconButton data-side="right" onClick={switchIndex}>
          <SvgIcon component={ArrowCircleRightOutlinedIcon} width={12} height={12} />
        </IconButton>
      </StyledReleaseBoxSwitcher>
    </StyledReleaseBox>
  );
};
