import { IAsset, IVestingBox } from "@framework/types";
import { SPLIT_BOX_PRICE_COMMISSION } from "@framework/constants/dist/commissions";

import { VestingBox } from "../../utils";
import { CalculationCurrencyWithValue, CalculationData } from "../../types";
import { CalculationSlides } from "./types";

const calculateBoxesPrice = (
  originalBoxTokenPrice: number,
  sideBoxTokensCount: number,
  vendorAllowanceValue: number,
) => {
  const sideBaseBoxPrice = originalBoxTokenPrice * sideBoxTokensCount;
  const withBenefitsBoxPrice = sideBaseBoxPrice * (1 + vendorAllowanceValue / 100);
  const tokenPrice = withBenefitsBoxPrice / sideBoxTokensCount;

  return {
    boxPrice: withBenefitsBoxPrice,
    tokenPrice,
  };
};

export const splitCalculation = (
  calculationSlides: Required<CalculationSlides>,
  vendorAllowanceValue: number,
  box: IVestingBox,
  price: IAsset,
): {
  leftBoxCalculation: CalculationData;
  rightBoxCalculation: CalculationData;
  isNothingForSplit?: boolean;
} => {
  const vesting = new VestingBox(box, price);

  /** Boxes tokens count calculation **/
  const contentTicker = vesting.getContentTicker();
  const priceTicker = vesting.getPriceTicker() as "USDT" | "USDC";
  const contentAmount = vesting.getContentAmount();
  const priceAmount = vesting.getPriceAmount();
  const unsplitPercentage = calculationSlides.releasablePercentage + calculationSlides.withdrawnPercentage;

  const totalForSplitCount =
    unsplitPercentage === 0
      ? contentAmount
      : unsplitPercentage > 100
        ? 0
        : contentAmount * ((100 - unsplitPercentage) / 100);

  if (totalForSplitCount === 0) {
    return {
      isNothingForSplit: true,
      leftBoxCalculation: {
        tokensCount: `${contentTicker} ${0}`,
        tokenPrice: `${priceTicker} ${0}`,
        boxPrice: `${priceTicker} ${0}`,
        platformFee: `${0}`,
        benefit: "-",
      },
      rightBoxCalculation: {
        tokensCount: `${contentTicker} ${0}`,
        tokenPrice: `${priceTicker} ${0}`,
        boxPrice: `${priceTicker} ${0}`,
        platformFee: `${0}`,
        benefit: "-",
      },
    };
  }

  const leftBoxTokensCount = (totalForSplitCount * calculationSlides.splitPercentage) / 100;
  const rightBoxTokensCount = totalForSplitCount - leftBoxTokensCount;

  /** Boxes prices calculation **/
  const originalBoxTokenPrice = vesting.getContentTokenPrice();
  const originalBoxPrice = priceAmount;
  const commissionValue = SPLIT_BOX_PRICE_COMMISSION / 100;

  const { boxPrice: leftBoxPrice, tokenPrice: leftTokenPrice } = calculateBoxesPrice(
    originalBoxTokenPrice,
    leftBoxTokensCount,
    vendorAllowanceValue,
  );
  const { boxPrice: rightBoxPrice, tokenPrice: rightTokenPrice } = calculateBoxesPrice(
    originalBoxTokenPrice,
    rightBoxTokensCount,
    vendorAllowanceValue,
  );

  /** Boxes benefit **/
  const leftBoxBenefit = leftBoxPrice - originalBoxPrice - leftBoxPrice * commissionValue;

  const rightBoxBenefit = rightBoxPrice - originalBoxPrice - rightBoxPrice * commissionValue;

  /** Result box price connected with benefit **/
  const leftPlatformFee: number = leftBoxPrice - leftBoxPrice * (1 - commissionValue);
  let resultLeftBoxBenefit: CalculationCurrencyWithValue = `${priceTicker} ${leftBoxBenefit}`;

  const rightPlatformFee: number = rightBoxPrice - rightBoxPrice * (1 - commissionValue);
  let resultRightBoxBenefit: CalculationCurrencyWithValue = `${priceTicker} ${rightBoxBenefit}`;

  if (leftBoxBenefit < 0) {
    resultLeftBoxBenefit = "-";
  }

  if (rightBoxBenefit < 0) {
    resultRightBoxBenefit = "-";
  }

  return {
    leftBoxCalculation: {
      tokensCount: `${contentTicker} ${leftBoxTokensCount}`,
      tokenPrice: `${priceTicker} ${leftTokenPrice}`,
      boxPrice: `${priceTicker} ${leftBoxPrice}`,
      platformFee: `${leftPlatformFee}`,
      benefit: resultLeftBoxBenefit,
    },
    rightBoxCalculation: {
      tokensCount: `${contentTicker} ${rightBoxTokensCount}`,
      tokenPrice: `${priceTicker} ${rightTokenPrice}`,
      boxPrice: `${priceTicker} ${rightBoxPrice}`,
      platformFee: `${rightPlatformFee}`,
      benefit: resultRightBoxBenefit,
    },
  };
};

export function calculationSlidesMaxValues(calculationSlides: Required<CalculationSlides>): {
  [Key in keyof CalculationSlides]: number;
} {
  return {
    splitPercentage: 100,
    releasablePercentage: 100 - calculationSlides.withdrawnPercentage,
    withdrawnPercentage: 100 - calculationSlides.releasablePercentage,
  };
}
