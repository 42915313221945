import { SPLIT_BOX_PRICE_COMMISSION } from "@framework/constants";

import { Vesting } from "../../../../shared";

export const calculateOfferInfo = (vesting: Vesting, vendorAllowanceValue: number) => {
  const commissionValue = SPLIT_BOX_PRICE_COMMISSION / 100;

  const priceAmount = vesting.getPriceAmount();
  const tokensCount = vesting.getContentAmount();
  const tvl = vesting.getTvl();
  const boxPriceTicker = vesting.getPriceTicker();

  const withdrawnBoxPriceAmount = (tvl * priceAmount) / tokensCount;

  const boxPriceWithBenefit = withdrawnBoxPriceAmount * (1 + vendorAllowanceValue / 100);
  const tokenPrice = boxPriceWithBenefit / tvl;

  const platformFee: number = boxPriceWithBenefit - boxPriceWithBenefit * (1 - commissionValue);
  let benefit: string | number = boxPriceWithBenefit - priceAmount - boxPriceWithBenefit * commissionValue;

  if (benefit < 0) {
    benefit = "-";
  }

  return {
    tokensCount: tvl,
    tokenPrice: `${boxPriceTicker} ${tokenPrice}`,
    boxPrice: `${boxPriceTicker} ${boxPriceWithBenefit}`,
    platformFee: `${platformFee}`,
    benefit,
  };
};
