import { IVestingBox, ShapeType } from "@framework/types";

import { plotDataByShape, Seconds, secondsValues, TPlotConfig } from "../index";

export type BoxPlotValues = Partial<
  Pick<IVestingBox, "shape" | "cliff" | "duration" | "period" | "afterCliffBasisPoints" | "growthRate"> & {
    startTimestamp: Date;
  }
>;

export const useRenderPlot = (boxValues: BoxPlotValues) => {
  const {
    shape = ShapeType.LINEAR,
    cliff = 0,
    duration = secondsValues[Seconds.YEAR],
    period = 1,
    afterCliffBasisPoints = 0,
    growthRate = 100,
    startTimestamp = new Date(),
  } = boxValues;

  const durationInDays = duration / secondsValues[Seconds.ONE_DAY];
  const cliffInDays = cliff / secondsValues[Seconds.ONE_DAY];
  const periodInDays =
    period !== 1
      ? period / secondsValues[Seconds.ONE_DAY]
      : secondsValues[Seconds.MONTH] / secondsValues[Seconds.ONE_DAY];
  const immediateUnlockPercentage = afterCliffBasisPoints / 100;
  const immediateUnlockPercentageRestPercent = immediateUnlockPercentage ? 100 - immediateUnlockPercentage : 0;
  const nthGrowthRate = growthRate / 100;

  const config: TPlotConfig = {
    duration: durationInDays + cliffInDays,
    cliff: cliffInDays,
    period: periodInDays,
    growthRate: nthGrowthRate,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    afterCliffBasisPoints,
    startTimestamp: startTimestamp.toISOString(),
  };

  return { data: plotDataByShape[shape](config) };
};
