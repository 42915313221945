import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

import { IVestingBox } from "@framework/types";
import { StyledAvatar } from "@framework/styled";

import { BoxContentLeftSide, StyledContentRightSide, Vesting } from "../../../../../../shared";
import { Root, StyledContent } from "../styled";
import { StyledContentPrices, StyledContentPricesItem } from "./styled";
import { ReleaseBox } from "./release-box";
import { ProgressBars } from "./progress-bars";

interface ICurrentBoxContent {
  selected: IVestingBox;
  tokenId?: string | null;
  vesting: Vesting;
  withdrawn: number;
}

export const CurrentBoxContent: FC<ICurrentBoxContent> = props => {
  const { selected, tokenId, withdrawn, vesting } = props;

  const { formatMessage } = useIntl();

  const tokensCount = vesting.getContentAmount();

  const tokensCountWithTicker = vesting.getTokensCountWithTicker(withdrawn);
  const tokenPriceWithTicker = vesting.getContentTokenPriceWithTicker(withdrawn);

  const boxPriceWithTicker = vesting.getBoxPriceWithTicker();
  const releasablePercentage = vesting.getReleasablePercentageAmount();

  return (
    <Root>
      <StyledContent container spacing={2} direction={"column"}>
        <BoxContentLeftSide
          tokenImageUrl={selected.content!.components[0].template!.imageUrl}
          tokensCount={tokensCountWithTicker}
          tooltipTextId="pages.vesting.currentBox.tokensInfo"
          subtitle={formatMessage({ id: "pages.vesting.currentBox.tokensCount" })}
        />

        <StyledContentRightSide size={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <ProgressBars vestingBox={selected} releasablePercentage={releasablePercentage} tokenId={tokenId} />

          <ReleaseBox tokensCount={tokensCount} duration={selected.duration} shape={selected.shape} />
        </StyledContentRightSide>
      </StyledContent>
      <StyledContentPrices>
        <StyledContentPricesItem>
          <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
          <Box className="PricesItem-content">
            <Typography variant="body1" component="p">
              {`${formatMessage({ id: "pages.vesting.currentBox.tokenPrice" })}`}
            </Typography>
            <Typography variant="body2" component="p">
              {tokenPriceWithTicker}
            </Typography>
          </Box>
        </StyledContentPricesItem>
        <StyledContentPricesItem>
          <StyledAvatar src={selected.template!.price!.components[0].template!.imageUrl} />
          <Box className="PricesItem-content">
            <Typography variant="body1" component="p">
              {`${formatMessage({ id: "pages.vesting.currentBox.boxPrice" })}`}
            </Typography>
            <Typography variant="body2" component="p">
              {boxPriceWithTicker}
            </Typography>
          </Box>
        </StyledContentPricesItem>
      </StyledContentPrices>
    </Root>
  );
};
