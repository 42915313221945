import { ShapeType } from "@framework/types";

import { Seconds, TPlotConfig } from "../types";
import {
  formatDateForVestingPlot,
  generateExponentialSteppedData,
  generateHyperbolicSteppedData,
  generateLinearSteppedData,
} from "../utils";

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const secondsValues: Record<Seconds, number> = {
  [Seconds.TEN_MINUTES]: 600,
  [Seconds.THIRTY_MINUTES]: 1800,
  [Seconds.ONE_HOUR]: 3600,
  [Seconds.THREE_HOURS]: 10800,
  [Seconds.SIX_HOURS]: 21600,
  [Seconds.TWELVE_HOURS]: 43200,
  [Seconds.ONE_DAY]: 86400,
  [Seconds.TWO_DAYS]: 172800,
  [Seconds.SEVEN_DAYS]: 604800,
  [Seconds.MONTH]: 2592000,
  [Seconds.THREE_MONTH]: 7776000,
  [Seconds.SIX_MONTH]: 15552000,
  [Seconds.YEAR]: 31104000,
  [Seconds.TWO_YEARS]: 62208000,
};

export const plotDataByShape: Record<ShapeType, (config: TPlotConfig) => Array<any>> = {
  [ShapeType.LINEAR]: ({ duration, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x += 1) {
      const y = (x / duration) * 100;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x, false, true);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_CLIFF]: ({ duration, cliff, startTimestamp }) => {
    let prevDate = startTimestamp;
    const data = [];

    // show only 2 points on the X axis to display the cliff
    for (let x = 0; x <= 4; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? cliff / 4 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: !x ? formattedDate : null, y: 0 });
    }

    for (let x = cliff; x <= duration; x++) {
      const y = ((x - cliff) / (duration - cliff)) * 100;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    for (let x = 0; x <= duration; x++) {
      const y = immediateUnlockPercentage + (x / duration) * immediateUnlockPercentageRestPercent;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_CLIFF_IMMEDIATE]: ({
    duration,
    cliff,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
  }) => {
    let prevDate = startTimestamp;
    const data = [];

    // show only 2 points on the X axis to display the cliff
    for (let x = 0; x <= 4; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? cliff / 4 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: !x ? formattedDate : null, y: 0 });
    }

    for (let x = cliff; x <= duration; x++) {
      const y = ((x - cliff) / (duration - cliff)) * immediateUnlockPercentageRestPercent + immediateUnlockPercentage;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? 1 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL]: ({ duration, growthRate, startTimestamp, period }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = duration / period;
    for (let i = 0; i <= targetPeriods; i++) {
      const x = Math.round((i * duration) / targetPeriods);
      const y = growthRate ** (x / period - duration / period) * 100 * (x / duration);
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL_CLIFF]: ({ duration, cliff, growthRate, startTimestamp, period }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = (duration - cliff) / period;

    // show only 2 points on the X axis to display the cliff
    for (let x = 0; x <= 2; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? cliff / 2 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: !x ? formattedDate : null, y: 0 });
    }

    for (let i = 0; i <= targetPeriods; i++) {
      const x = Math.round((i * (duration - cliff)) / targetPeriods);
      const y = growthRate ** (x / period - (duration - cliff) / period) * 100 * (x / (duration - cliff));
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    growthRate,
    startTimestamp,
    period,
  }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = duration / period;

    for (let i = 0; i <= targetPeriods; i++) {
      const x = Math.round((i * duration) / targetPeriods);
      const y =
        immediateUnlockPercentage +
        growthRate ** (x / period - duration / period) * immediateUnlockPercentageRestPercent * (x / duration);
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE]: ({
    duration,
    cliff,
    growthRate,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
    period,
  }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = (duration - cliff) / period;

    // show only 2 points on the X axis to display the cliff
    for (let x = 0; x <= 2; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? cliff / 2 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: !x ? formattedDate : null, y: 0 });
    }

    for (let i = 0; i <= targetPeriods; i++) {
      const x = Math.round((i * (duration - cliff)) / targetPeriods);
      const y =
        immediateUnlockPercentage +
        growthRate ** (x / period - (duration - cliff) / period) *
          immediateUnlockPercentageRestPercent *
          (x / (duration - cliff));
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC]: ({ duration, startTimestamp, period }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = duration / period;

    for (let i = 0; i <= targetPeriods; i++) {
      const x = (i * duration) / targetPeriods;
      const y = (100 * (x / period)) / (x / period + 1);
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;
      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC_CLIFF]: ({ duration, cliff, startTimestamp, period }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = (duration - cliff) / period;

    // show only 2 points on the X axis to display the cliff
    for (let x = 0; x <= 2; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? cliff / 2 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: !x ? formattedDate : null, y: 0 });
    }

    for (let i = 0; i <= targetPeriods; i++) {
      const x = (i * (duration - cliff)) / targetPeriods;
      const y = (100 * (x / period)) / (x / period + 1);
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    startTimestamp,
    period,
  }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = duration / period;

    for (let i = 0; i <= targetPeriods; i++) {
      const x = (i * duration) / targetPeriods;
      const hyperbolaPart = (immediateUnlockPercentageRestPercent * (x / period)) / (x / period + 1);
      const y = immediateUnlockPercentage + hyperbolaPart;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.HYPERBOLIC_CLIFF_IMMEDIATE]: ({ duration, cliff, immediateUnlockPercentage, startTimestamp, period }) => {
    let prevDate = startTimestamp;
    const data = [];
    const targetPeriods = (duration - cliff) / period;

    // show only 2 points on the X axis to display the cliff
    for (let x = 0; x <= 2; x++) {
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? cliff / 2 : x, false, true);
      prevDate = formattedDate;
      data.push({ x: !x ? formattedDate : null, y: 0 });
    }

    for (let i = 0; i <= targetPeriods; i++) {
      const x = (i * (duration - cliff)) / targetPeriods;
      const hyperbolaPart = ((100 - immediateUnlockPercentage) * (x / period)) / (x / period + 1);
      const y = immediateUnlockPercentage + hyperbolaPart;
      const formattedDate = formatDateForVestingPlot(prevDate, x > 0 ? period : 0, false, true);
      prevDate = formattedDate;

      data.push({ x: formattedDate, y });
    }

    return data;
  },
  [ShapeType.LINEAR_MONTHLY_STEPS]: ({ duration, period, startTimestamp }) =>
    generateLinearSteppedData({ duration, period, startTimestamp }),
  [ShapeType.EXPONENTIAL_MONTHLY_STEPS]: ({ duration, period, growthRate, startTimestamp }) =>
    generateExponentialSteppedData({ duration, period, growthRate, startTimestamp }),
  [ShapeType.HYPERBOLIC_MONTHLY_STEPS]: ({ duration, period, startTimestamp }) =>
    generateHyperbolicSteppedData({ duration, period, startTimestamp }),
};
