import React, { ChangeEvent, useMemo, useState } from "react";
import { Grid2 } from "@mui/material";
import { useIntl } from "react-intl";

import { ListActionVariant } from "@framework/styled";

import { CustomInput, WithBorderInfoBox } from "../../../../shared";
import { MakeOfferButton } from "../../../../components/buttons/offer";
import { Summary } from "./summary";
import { gridItemsSizes, StyledRoot } from "./styled";
import { IOfferManagementProps } from "./types";
import { calculateOfferInfo } from "./utils";

export const OfferManagement = React.memo((props: IOfferManagementProps) => {
  const { summaryData, vesting, template, boxId, tokenId, priceAsset, contentAsset } = props;
  const [vendorAllowanceValue, setVendorAllowanceValue] = useState("0");

  const { formatMessage } = useIntl();

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVendorAllowanceValue(event.target.value);
  };

  const infoData = useMemo(() => {
    return calculateOfferInfo(vesting, Number(vendorAllowanceValue));
  }, [vendorAllowanceValue, vesting]);

  const tokensCount = vesting.getContentAmount();

  const price = {
    ...priceAsset,
    components: priceAsset.components.map(c => ({
      ...c,
      amount: (Number(infoData.boxPrice.split(" ")[1]) * 1e18).toString(),
    })),
  };

  const content = {
    ...contentAsset,
    components: contentAsset.components.map(c => ({
      ...c,
      amount: (tokensCount * 1e18).toString(),
    })),
  };

  return (
    <StyledRoot container spacing={2}>
      <Grid2 className="OfferManagement-offerBox" size={{ ...gridItemsSizes, lg: 12, md: 12, xl: 12 }}>
        <CustomInput
          label={formatMessage(
            { id: "pages.vesting.currentBox.calculationSlides.vendorAllowance" },
            { value: vendorAllowanceValue },
          )}
          onChange={onInputChange}
        />
        <MakeOfferButton
          className="OfferManagement-offerBtn"
          variant={ListActionVariant.button}
          template={template}
          offer={{ boxId, tokenId, price, content, templateId: template.id }}
        />
      </Grid2>
      <Grid2 size={gridItemsSizes}>
        <Summary token={summaryData.token} price={summaryData.price} />
      </Grid2>
      <Grid2 size={gridItemsSizes}>
        <WithBorderInfoBox title={formatMessage({ id: "pages.tokens.current.infoBlockTitle" })} data={infoData} />
      </Grid2>
    </StyledRoot>
  );
});
