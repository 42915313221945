import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Grid2 } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";

import { ListAction, ListActionVariant } from "@framework/styled";

import {
  formatDateFromSpecialValue,
  IVestingOfferResponse,
  TOKEN_PERCENTAGE_FIX_VALUE,
  useVestingContractInfo,
  Vesting,
} from "../../../../../shared";
import { OfferAssetRoot } from "../../styled";
import { OfferAssetItem } from "../styled";
import { offersTableHeaderSchema } from "../schemas";

interface IOfferAssetProps {
  offer: IVestingOfferResponse;
  handleDelete: (item: IVestingOfferResponse) => () => void;
  isMd?: boolean;
}

export const OfferAsset: FC<IOfferAssetProps> = props => {
  const { offer, handleDelete, isMd } = props;
  const { box, tokenId, createdAt } = offer;

  const { releasable, withdrawn } = useVestingContractInfo(tokenId || "", box.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(box, box.template!.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  const displayingBoxPrice = vesting.getBoxPriceWithTicker();
  const displayingTokensCount = vesting.getTokensCountWithTicker(withdrawn);
  const displayingReleasable = releasable.toFixed(TOKEN_PERCENTAGE_FIX_VALUE);
  const displayingReleasablePercentage = vesting.getReleasablePercentageDisplaying();
  const displayingDate = formatDateFromSpecialValue(createdAt, true);

  const displayingData = [
    box.template!.title,
    displayingBoxPrice,
    displayingTokensCount,
    `${displayingReleasable} (${displayingReleasablePercentage}%)`,
    displayingDate,
    <ListAction
      key={"close_offer"}
      onClick={handleDelete(offer)}
      message="pages.tokens.closeOffer"
      className={"CloseOffer-btn"}
      dataTestId="CloseOfferButton"
      icon={DeleteOutlined}
      variant={!isMd ? ListActionVariant.iconButton : ListActionVariant.button}
    />,
  ];

  return (
    <OfferAssetRoot className="TableRow-root" container size={12} spacing={2}>
      {displayingData.map((dataItem, index, array) => {
        const isLastItem = index === array.length - 1;
        return !isMd ? (
          <OfferAssetItem isFirst={index === 0} size={isLastItem ? 1 : 13 / array.length} key={index}>
            {dataItem}
          </OfferAssetItem>
        ) : (
          <OfferAssetItem container size={12} key={index} spacing={2}>
            {!isLastItem && (
              <Grid2 size={6}>
                <FormattedMessage id={offersTableHeaderSchema[index]} tagName="span" />
              </Grid2>
            )}
            <Grid2 sx={{ textAlign: "right" }} size={isLastItem ? 12 : 6}>
              {dataItem}
            </Grid2>
          </OfferAssetItem>
        );
      })}
    </OfferAssetRoot>
  );
};
