import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { Box as MuiBox, IconButton } from "@mui/material";

import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { ListActionVariant } from "@framework/styled";

import {
  AssetsDashboardTabs,
  BoxesAssetVariant,
  BoxWithTitle,
  ContentMode,
  CustomAccordion,
  Plot,
  SplitCalculation,
  StyledCurrentPageHeader,
  StyledCurrentRoot,
  useVestingContractInfo,
  Vesting,
} from "../../../shared";
import { ButtonBack } from "../../../shared/assets/svg/button-back";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { VestingBoxReleaseButton } from "../../../components/buttons/release";
import { BoxContent } from "./content";
import { RulesDescription } from "./rules-description";
import { StyledContentWrapper } from "./styled";
import { OfferManagement } from "./offer-management";
import { ISelected } from "./useFetchToken";
import { createOfferDescription, splitRulesDescription } from "./schemas";

interface ICurrentBoxProps {
  selected: ISelected;
  variant?: BoxesAssetVariant;
}

export const Box: FC<ICurrentBoxProps> = props => {
  const { selected, variant = AssetsDashboardTabs.boxes } = props;
  const { template, tokenId } = selected;

  const { releasable, withdrawn } = useVestingContractInfo(tokenId, template.contract!.address);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const goBackHandler = () => navigate(-1);

  const imageUrl = template.box.content!.components[0].template!.imageUrl || "";

  const vesting = useMemo(() => {
    return new Vesting(template.box, template.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  return (
    <StyledCurrentRoot>
      <Breadcrumbs
        paths={[`assets-dashboard.${variant}`, "tokens.current"]}
        data={[{}, { title: template.box.title }]}
      />

      <StyledCurrentPageHeader>
        <IconButton onClick={goBackHandler}>
          <ButtonBack />
        </IconButton>
        <PageHeader message="pages.tokens.current.title" data={{ title: template.box.title }} />
      </StyledCurrentPageHeader>

      <StyledContentWrapper>
        <BoxContent imageUrl={imageUrl} vesting={vesting}>
          <MuiBox sx={{ width: "100%" }} className="CurrentToken-Buttons-box">
            <VestingBoxReleaseButton
              className="CurrentToken-releaseBtn"
              token={selected}
              variant={ListActionVariant.button}
            />
          </MuiBox>
        </BoxContent>

        <BoxWithTitle
          title={formatMessage({ id: "pages.tokens.current.tokenDynamicsTitle" })}
          description={formatMessage({ id: "pages.tokens.current.tokenDynamicsDescription" })}
        >
          <Plot
            vestingBox={template.box}
            price={template.price!}
            startTimestamp={new Date(template.createdAt)}
            height={400}
            startDirection="column"
            infoPosition="bottom"
          />
        </BoxWithTitle>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.offerManagementTitle" })}>
          <CustomAccordion
            title={formatMessage({ id: "pages.tokens.current.offerRulesTitle" })}
            description={<RulesDescription data={createOfferDescription} />}
          />
          <OfferManagement
            priceAsset={template.price!}
            contentAsset={template.box.content!}
            template={template}
            boxId={template.box.id}
            tokenId={tokenId}
            summaryData={{
              token: {
                name: vesting.getContentTicker(),
                image: template.box.content?.components[0].contract?.imageUrl || "",
              },
              price: {
                name: vesting.getPriceTicker(),
                image: template.price?.components[0].contract?.imageUrl || "",
              },
            }}
            vesting={vesting}
          />
        </BoxWithTitle>

        <BoxWithTitle title={formatMessage({ id: "pages.tokens.current.splitManagementTitle" })}>
          <CustomAccordion
            title={formatMessage({ id: "pages.tokens.current.splitRulesTitle" })}
            description={<RulesDescription data={splitRulesDescription} />}
          />
          <SplitCalculation
            mode={ContentMode.ASSET}
            box={template.box}
            token={selected}
            releasablePercentage={vesting.getReleasablePercentageAmount()}
            withdrawnPercentage={vesting.getWithdrawnPercentageAmount()}
          />
        </BoxWithTitle>
      </StyledContentWrapper>
    </StyledCurrentRoot>
  );
};
