import { Grid2, styled } from "@mui/material";

export const StyledSlidesBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),

  "& .CurrentToken-splitBtn": {
    minWidth: "158px",
    background: "#285513",
    border: "1px solid #285513",
    color: "#FFFFFF",
    fontSize: theme.spacing(2),
    textTransform: "none",
    borderRadius: theme.spacing(3.75),
    padding: theme.spacing(1.5, 2.5),
    "&:hover": {
      background: "#448327",
    },
    "&.Mui-disabled": {
      color: "#FFFFFF",
    },
  },
}));

export const StyledCalculationBox = styled(Grid2)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(2),
}));
