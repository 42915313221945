import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { IVestingBox } from "@framework/types";

import { VestingBox } from "../../../../../../../shared";
import { Root, StyledDatesItem } from "./styled";

interface ITimelineDatesProps {
  vestingBox: IVestingBox;
  startDate?: string | null;
}

export const TimelineDates: FC<ITimelineDatesProps> = props => {
  const { vestingBox, startDate } = props;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const { formatMessage } = useIntl();

  const vesting = useMemo(() => {
    return new VestingBox(vestingBox, vestingBox.template!.price!);
  }, [vestingBox]);

  const { tge, ido, end, cliffEnds, duration, unlockPeriod } = vesting.getBoxInfo(startDate, true);

  const timelineData = Object.entries({ ido, cliffEnds, tge, unlockPeriod, end, duration }).map(([key, value]) => {
    if (key === "cliffEnds" && !vestingBox.cliff) return null;

    if (key === "unlockPeriod") {
      return {
        id: `pages.vesting.currentBox.${key}`,
        value: `${formatMessage({ id: "pages.vesting.currentBox.unlockPeriodSubtext" }, { value })}`,
      };
    }

    return {
      id: `pages.vesting.currentBox.${key}`,
      value,
    };
  });

  return (
    <Root
      container
      spacing={2}
      alignItems={isXs ? "flex-start" : "center"}
      justifyContent={isXs ? "flex-start" : "center"}
    >
      {timelineData.map(dataItem => {
        if (!dataItem) return null;

        return (
          <StyledDatesItem
            size={{ lg: 12, md: 12, sm: 12, xs: 12 }}
            alignItems={"flex-start"}
            justifyContent={"center"}
            key={dataItem.id}
          >
            <Box className="DateItem-text">
              <FormattedMessage id={dataItem.id} tagName="span" />
              <Typography variant="body1" component="span">
                {dataItem.value}
              </Typography>
            </Box>
          </StyledDatesItem>
        );
      })}
    </Root>
  );
};
