import React, { FC, useMemo, useState } from "react";
import { Box, SvgIcon, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { IVestingBox } from "@framework/types";

import { getBackgroundImg, sizeDecreaseCalc, Vesting } from "../../utils";
import { AMOUNT_FIX_VALUE, INFINITY_DISPLAYING, PATHS, TOKEN_DISPLAYING_IN_CARD_FIX_VALUE } from "../../constants";
import { StyledCardMedia } from "../styled";
import {
  Root,
  StyledAvatar,
  StyledCardActionArea,
  StyledCardActions,
  StyledCardActionsInfo,
  StyledCardActionsTopInfoRight,
} from "./styled";
import { VestingBoxCategoryBadge } from "../../../components/common/badge";
import { pageHeader } from "../../styles";
import { useVestingContractInfo } from "../../hooks";
import { VestingBoxesTabs } from "../../types";

interface IBoxCard {
  vestingBox: IVestingBox;
  tab: VestingBoxesTabs;
  offerId?: number;
  tokenId?: string;
}

export const BoxCard: FC<IBoxCard> = props => {
  const { vestingBox, tab, tokenId, offerId } = props;

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const { releasable, withdrawn } = useVestingContractInfo(tokenId || "", vestingBox.template!.contract!.address);

  const vesting = useMemo(() => {
    return new Vesting(vestingBox, vestingBox.template!.price!, releasable, withdrawn);
  }, [releasable, withdrawn]);

  const duration = vesting.getBoxInfo().duration;
  const tokensAmount = vesting.getContentAmount();
  const boxPrice = vesting.getBoxPriceWithTicker(AMOUNT_FIX_VALUE);
  const tokenImg = vestingBox ? vestingBox.content!.components[0].template!.imageUrl : "";
  const releasablePercentageForDisplaying = vesting.getReleasablePercentageDisplaying();

  const immediateUnlock =
    !tokenId || !offerId ? vestingBox.afterCliffBasisPoints / 100 : releasablePercentageForDisplaying;

  const tvl = !offerId ? tokensAmount : vesting.getTvl();

  const backgroundImg = vestingBox.backgroundImg || getBackgroundImg(vestingBox.vestingBoxCat);

  const limitDisplaying =
    Number(vestingBox.template!.cap) > 0
      ? `${Number(vestingBox.template!.cap) - Number(vestingBox.template!.amount)}/${vestingBox.template!.cap}`
      : INFINITY_DISPLAYING;

  return (
    <Root
      component={Link}
      to={`${PATHS.VestingBoxesBase}/${tab}/${offerId || vestingBox.id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <StyledCardActionArea
        sx={{
          transform: isHovered ? "scale(1.04)" : "scale(1.0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <VestingBoxCategoryBadge category={vestingBox.vestingBoxCat} />
        <StyledCardMedia className="BoxCard-media" component="img" image={backgroundImg} />
      </StyledCardActionArea>
      <StyledCardActions>
        <Typography
          sx={{
            ...pageHeader(),
            fontSize: sizeDecreaseCalc(20, 16),
          }}
          variant="body1"
          component="h5"
        >
          {vestingBox.title}
        </Typography>
        <StyledCardActionsInfo sx={{ paddingTop: 1.25 }}>
          <FormattedMessage tagName="span" id="pages.vesting.boxes.limit" values={{ value: limitDisplaying }} />
          <StyledCardActionsTopInfoRight>
            <Box className="CardTopInfo-item">
              <span className="">{boxPrice}</span>
            </Box>
            {duration && (
              <Box className="CardTopInfo-item">
                <SvgIcon component={LockIcon} width={12} height={12} />
                <span className="">{duration}</span>
              </Box>
            )}
            <Box className="CardTopInfo-item">
              <SvgIcon component={PersonIcon} width={12} height={12} />
              <span className="CardTopInfoItem-subtitle">{vestingBox.template!.amount}</span>
            </Box>
          </StyledCardActionsTopInfoRight>
        </StyledCardActionsInfo>
        <StyledCardActionsInfo sx={{ paddingTop: 1.25 }}>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.vesting.boxes.tvl" })}</span>
          <span className="CardTopInfoItem-semibold">{formatMessage({ id: "pages.vesting.boxes.tgeUnlock" })}</span>
        </StyledCardActionsInfo>
        <StyledCardActionsInfo>
          {tvl && (
            <Box className="CardTopInfo-item">
              <StyledAvatar
                component="img"
                className="BoxCard-avatar"
                src={tokenImg}
                alt={"#"}
                width={40}
                height={40}
              />
              <span className="CardTopInfoItem-bold">{tvl.toFixed(TOKEN_DISPLAYING_IN_CARD_FIX_VALUE)}</span>
            </Box>
          )}
          <span className="CardTopInfoItem-green">{`${immediateUnlock}%`}</span>
        </StyledCardActionsInfo>
      </StyledCardActions>
    </Root>
  );
};
