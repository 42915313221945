import { Box, styled } from "@mui/material";

import { pageHeader } from "../../styles";

export const StyledListRoot = styled(Box)(({ theme }) => ({
  minHeight: "100vh",

  "& h2": {
    ...pageHeader(),
  },

  "& .MuiPaginationItem-root": {
    background: "rgba(0, 0, 0, 0.25)",
    color: "rgba(0, 0, 0, 0.87)",
  },

  "& form > .MuiGrid-container > .MuiGrid-item": {
    paddingTop: 0,

    "& .MuiPaper-elevation": {
      borderRadius: theme.spacing(1),
    },
  },

  "& .BoxesFilters-autocomplete": {
    "& .MuiAutocomplete-root": {
      margin: 0,
    },

    "& .MuiChip-root": {
      backgroundColor: "#1B5DA5",

      "& .MuiChip-label": {
        color: "#FFFFFF",
      },

      "& .MuiSvgIcon-root": {
        fill: "#FFFFFF",
      },
    },
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: theme.spacing(1),
    backgroundColor: "#FFFFFF",
  },
}));
