import { FC, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Grid2, useMediaQuery, useTheme } from "@mui/material";
import {
  Root,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledContent,
  StyledCopyrightWrapper,
  StyledWrapper,
} from "./styled";
import { legalDisclaimerParagraphs } from "./constants";
import { Logos } from "./logos";

const fullYear = new Date().getFullYear();

export const Footer: FC = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const toggleExpanded = () => (_event: SyntheticEvent) => {
    setExpanded(prevState => !prevState);
  };

  return (
    <Root>
      <StyledWrapper>
        <StyledContent container size={12} spacing={isMd ? 2 : 5}>
          <Logos isMd={isMd} />
          <Grid2
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            size={12}
          >
            <StyledCopyrightWrapper>BDOU GLOBAL SOURCING LIMITED</StyledCopyrightWrapper>
            <StyledCopyrightWrapper>
              Workshop 21, 6/F, iCITY, 111 Ta Chuen Ping Street, Kwai Chung, Hong Kong
            </StyledCopyrightWrapper>
            <StyledCopyrightWrapper>Licenсe No. 22-03-03101</StyledCopyrightWrapper>
          </Grid2>
        </StyledContent>
        <StyledAccordion disableGutters elevation={0} expanded={expanded} onChange={toggleExpanded()}>
          <StyledAccordionSummary>
            <FormattedMessage id="components.footer.legalDisclaimer.title" tagName="span" />
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            {Object.values(legalDisclaimerParagraphs).map(id => (
              <FormattedMessage key={id} tagName={"p"} id={id} values={{ year: fullYear }} />
            ))}
          </StyledAccordionDetails>
        </StyledAccordion>
      </StyledWrapper>
    </Root>
  );
};
