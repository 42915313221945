import { FC } from "react";
import { useWeb3React, Web3ContextType } from "@web3-react/core";
import { constants, Contract, utils } from "ethers";
import { ShoppingCart } from "@mui/icons-material";

import { useUser } from "@dzambalaorg/provider-user";
import { useAppDispatch, useAppSelector } from "@dzambalaorg/redux";
import { walletActions, walletSelectors } from "@dzambalaorg/provider-wallet";
import { useAllowance, useMetamask, useServerSignature } from "@dzambalaorg/react-hooks-eth";
import type { IServerSignature } from "@dzambalaorg/types-blockchain";
import {
  convertDatabaseAssetToChainAsset,
  convertDatabaseAssetToTokenTypeAsset,
  convertTemplateToChainAsset,
  getEthPrice,
} from "@framework/exchange";
import { ListAction, ListActionVariant } from "@framework/styled";
import type { IContract, IUser } from "@framework/types";

import ExchangePurchaseOtcFacetPurchaseOtcABI from "@framework/abis/json/ExchangePurchaseOtcFacet/purchaseOtc.json";
import { IVestingOfferResponse } from "../../../shared";

interface IOfferPurchaseButtonProps {
  offer: IVestingOfferResponse;
  className?: string;
  disabled?: boolean;
  variant?: ListActionVariant;
}

interface IAmountDto {
  amount: number;
}

export const OfferPurchaseButton: FC<IOfferPurchaseButtonProps> = props => {
  const {
    offer: { id: offerId, box, tokenId, owner },
    className,
    disabled,
    variant = ListActionVariant.button,
  } = props;

  const { isActive } = useWeb3React();
  const user = useUser<IUser>();
  const isUserAuthenticated = user.isAuthenticated();

  const referrer = useAppSelector(walletSelectors.referrerSelector);
  const dispatch = useAppDispatch();
  const { setIsDialogOpen } = walletActions;

  const metaFnWithAllowance = useAllowance(
    (web3Context: Web3ContextType, values: IAmountDto, sign: IServerSignature, systemContract: IContract) => {
      const contract = new Contract(
        systemContract.address,
        ExchangePurchaseOtcFacetPurchaseOtcABI,
        web3Context.provider?.getSigner(),
      );

      const item = convertTemplateToChainAsset(box.template, values.amount);
      const price = convertDatabaseAssetToChainAsset(box.template!.price?.components, { multiplier: values.amount });

      return contract.purchaseOtc(
        {
          externalId: box.template!.id,
          expiresAt: sign.expiresAt,
          nonce: utils.arrayify(sign.nonce),
          extra: utils.formatBytes32String("0x"),
          receiver: owner,
          referrer: referrer || constants.AddressZero,
        },
        { ...item, tokenId },
        price,
        sign.signature,
        {
          value: getEthPrice(box.template!.price).mul(values.amount),
        },
      ) as Promise<void>;
    },
  );

  const metaFnWithSign = useServerSignature(
    (values: IAmountDto, web3Context: Web3ContextType, sign: IServerSignature, systemContract: IContract) => {
      const assets = convertDatabaseAssetToTokenTypeAsset(box.template?.price?.components, {
        multiplier: values.amount,
      });
      return metaFnWithAllowance(
        {
          contract: systemContract.address,
          assets,
        },
        web3Context,
        values,
        sign,
        systemContract,
      );
    },
  );

  const metaFn = useMetamask((values: IAmountDto, web3Context: Web3ContextType) => {
    return metaFnWithSign(
      {
        url: "/marketplace/sign/offer",
        method: "POST",
        data: {
          referrer,
          offerId,
          templateId: box.template!.id,
          amount: values.amount,
        },
      },
      values,
      web3Context,
    ) as Promise<void>;
  });

  const handleConnect = () => {
    void dispatch(setIsDialogOpen(true));
  };

  const handleBuy = async () => {
    await metaFn({
      amount: 1,
    });
  };

  const templateCapDisabled =
    !isNaN(Number(box.template?.cap)) &&
    ~~Number(box.template?.cap) > 0 &&
    Number(box.template?.amount) >= Number(box.template?.cap);

  return (
    <ListAction
      icon={ShoppingCart}
      onClick={isActive && isUserAuthenticated ? handleBuy : handleConnect}
      message={isActive && isUserAuthenticated ? "form.buttons.buy" : "components.header.wallet.connect"}
      className={className}
      dataTestId="OfferPurchaseButton"
      disabled={disabled || templateCapDisabled}
      variant={variant}
    />
  );
};
